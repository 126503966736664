import Bugsnag from '@bugsnag/js'

export const state = () => ({
  provinces: [],
  cities: [],
  districts: [],
  subDistricts: [],
  featuredWholesalers: [],
  resellerCities: [],
  resellerDistricts: [],
  resellerCoverageArea: [],
  errMessage: null,
})

export const mutations = {
  // mutation
  SET_PROVINCES(state, data) {
    const s = state
    s.provinces = data
  },
  SET_CITIES(state, data) {
    const s = state
    s.cities = data
  },
  SET_DISTRICTS(state, data) {
    const s = state
    s.districts = data
  },
  SET_SUB_DISTRICTS(state, data) {
    const s = state
    s.subDistricts = data
  },
  SET_FEATURED_WHOLESALERS(state, data) {
    const s = state
    s.featuredWholesalers = data
  },
  SET_ERROR(state, data) {
    const s = state
    s.errMessage = data
  },
  SET_RESELLER_CITIES(state, data) {
    const s = state
    s.resellerCities = data
  },
  SET_RESELLER_DISTRICTS(state, data) {
    const s = state
    s.resellerDistricts = data
  },
  SET_RESELLER_COVERAGE_AREA(state, data) {
    const s = state
    s.resellerCoverageArea = data
  },
}

export const getters = {
  // getters
  getProvinces(state) {
    return state.provinces
  },
  getCities(state) {
    return state.cities
  },
  getDistricts(state) {
    return state.districts
  },
  getSubDistricts(state) {
    return state.subDistricts
  },
  getError(state) {
    return state.errMessage
  },
  getFeaturedWholesalers(state) {
    return state.featuredWholesalers
  },
  getResellerCities(state) {
    return state.resellerCities
  },
  getResellerDistricts(state) {
    return state.resellerDistricts
  },
  getResellerCoverageArea(state) {
    return state.resellerCoverageArea
  },
}

export const actions = {
  // actions
  async fetchProvinces({ commit }) {
    try {
      const url = 'public/state'
      const provinces = await this.$axios.$get(url)
      const { rows } = provinces
      if (rows) await commit('SET_PROVINCES', rows)
    } catch (error) {
      const err = error.response
      if (err?.status === 400) {
        this.$snackbar.showMessage({
          isShow: true,
          text: err?.data?.message || 'Unknown Error',
          registered: `GP-${err?.data?.statusCode}`,
        })
      } else {
        this.$snackbar.showMessage({
          isShow: true,
          text: error.message,
          registered: `GP-${err?.data?.statusCode}`,
        })
      }
      if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
        Bugsnag.notify(new Error(error))
      }
    }
  },
  async fetchCities({ commit }, id) {
    try {
      const url = `public/city?state_id=${id}`
      const cities = await this.$axios.$get(url)
      const { rows } = cities
      if (rows) await commit('SET_CITIES', rows)
    } catch (error) {
      const err = error.response
      if (err?.status === 400) {
        this.$snackbar.showMessage({
          isShow: true,
          text: err?.data?.message || 'Unknown Error',
          registered: `GC-${err?.data?.statusCode}`,
        })
      } else {
        this.$snackbar.showMessage({
          isShow: true,
          text: error.message,
          registered: `GC-${err?.data?.statusCode}`,
        })
      }
      if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
        Bugsnag.notify(new Error(error))
      }
    }
  },
  async fetchDistricts({ commit }, id) {
    try {
      const url = `public/district?city_id=${id}`
      const districts = await this.$axios.$get(url)
      const { rows } = districts
      if (rows) await commit('SET_DISTRICTS', rows)
    } catch (error) {
      const err = error.response
      if (err?.status === 400) {
        this.$snackbar.showMessage({
          isShow: true,
          text: err?.data?.message || 'Unknown Error',
          registered: `GD-${err?.data?.statusCode}`,
        })
      } else {
        this.$snackbar.showMessage({
          isShow: true,
          text: error.message,
          registered: `GD-${err?.data?.statusCode}`,
        })
      }
      if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
        Bugsnag.notify(new Error(error))
      }
    }
  },
  async fetchSubDistricts({ commit }, id) {
    try {
      const url = `/public/subdistrict?district_id=${id}`
      const subDistrict = await this.$axios.$get(url)
      const { rows } = subDistrict
      if (rows) await commit('SET_SUB_DISTRICTS', rows)
    } catch (error) {
      const err = error.response
      if (err?.status === 400) {
        this.$snackbar.showMessage({
          isShow: true,
          text: err?.data?.message || 'Unknown Error',
          registered: `GSD-${err?.data?.statusCode}`,
        })
      } else {
        this.$snackbar.showMessage({
          isShow: true,
          text: error.message,
          registered: `GSD-${err?.data?.statusCode}`,
        })
      }
      if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
        Bugsnag.notify(new Error(error))
      }
    }
  },
  async fetchFeaturedWholesalers({ commit }) {
    try {
      const url = '/guest/featured-wholesaler'
      const { rows } = await this.$axios.$get(url)
      if (rows) await commit('SET_FEATURED_WHOLESALERS', rows)
    } catch (error) {
      const err = error.response
      if (err?.status === 400) {
        this.$snackbar.showMessage({
          isShow: true,
          text: err?.data?.message || 'Unknown Error',
          registered: `GSD-${err?.data?.statusCode}`,
        })
      } else {
        this.$snackbar.showMessage({
          isShow: true,
          text: error.message,
          registered: `GSD-${err?.data?.statusCode}`,
        })
      }
      if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
        Bugsnag.notify(new Error(error))
      }
    }
  },
  async fetchResellerCities({ commit }) {
    try {
      const url = 'guest/cities'
      const cities = await this.$axios.$get(url)
      const { rows } = cities
      if (rows) await commit('SET_RESELLER_CITIES', rows)
    } catch (error) {
      const err = error.response
      if (err?.status === 400) {
        this.$snackbar.showMessage({
          isShow: true,
          text: err?.data?.message || 'Unknown Error',
          registered: `GRC-${err?.data?.statusCode}`,
        })
      } else {
        this.$snackbar.showMessage({
          isShow: true,
          text: error.message,
          registered: `GRC-${err?.data?.statusCode}`,
        })
      }
      if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
        Bugsnag.notify(new Error(error))
      }
    }
  },
  async fetchResellerDistricts({ commit }, { cityId, name }) {
    try {
      let url = 'guest/districts?'
      if (cityId) {
        url += `city_id=${cityId}`
      } else {
        url += `name=${name}`
      }
      const cities = await this.$axios.$get(url)
      const { rows } = cities
      if (rows) await commit('SET_RESELLER_DISTRICTS', rows)
    } catch (error) {
      const err = error.response
      if (err?.status === 400) {
        this.$snackbar.showMessage({
          isShow: true,
          text: err?.data?.message || 'Unknown Error',
          registered: `GRD-${err?.data?.statusCode}`,
        })
      } else {
        this.$snackbar.showMessage({
          isShow: true,
          text: error.message,
          registered: `GRD-${err?.data?.statusCode}`,
        })
      }
      if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
        Bugsnag.notify(new Error(error))
      }
    }
  },
  async fetchCoveragearea({ commit }, { cityId, districtId, coverageAreaId }) {
    try {
      const url = (cityId && districtId) ? `guest/coverage-area?city_id=${cityId}&district_id=${districtId}` : `guest/coverage-area?id=${coverageAreaId}`
      const areas = await this.$axios.$get(url)
      if (areas && areas.coverage_areas && areas.coverage_areas.length > 0) {
        await commit('SET_RESELLER_COVERAGE_AREA', areas.coverage_areas);
        return areas.coverage_areas;
      } else {
        return [];
      }
    } catch (error) {
      const err = error.response
      if (err?.status === 400) {
        this.$snackbar.showMessage({
          isShow: true,
          text: err?.data?.message || 'Unknown Error',
          registered: `GCA-${err?.data?.statusCode}`,
        })
      } else {
        this.$snackbar.showMessage({
          isShow: true,
          text: error.message,
          registered: `GCA-${err?.data?.statusCode}`,
        })
      }
      if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
        Bugsnag.notify(new Error(error))
      }
    }
  },
}
