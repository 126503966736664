//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Component Icon
 */
export default {
  name: 'LocationIcon',
}
