/* eslint-disable no-console */
/* eslint-disable node/handle-callback-err */
/* eslint-disable func-names */
/* eslint-disable no-unused-vars */

export default function({ $axios, route, redirect, app }) {
  $axios.onRequest(({ url, params }) => {
    if (process.env.NODE_ENV === 'development') {
      console.info(
        `API request to ${url} ${
          params ? `${JSON.stringify(params, null, 1)}` : ''
        }`
      )
    }
  })

  $axios.onResponseError((error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 403:
        case 502:
          if (process.env.NODE_ENV === 'development') {
            console.warn('response error', error)
          }
          app.$cookies.remove('gdotp')
          break
      }
    }
  })

  $axios.onError((error) => {})
}
