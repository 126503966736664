import Vue from 'vue'
import moment from 'moment'
import VueClipboard from 'vue-clipboard2'
import Bugsnag from '@bugsnag/js'

VueClipboard.config.autoSetContainer = true

Vue.use(VueClipboard)

Vue.mixin({
  methods: {
    /**
     * @function dateFormat : sebuah function untuk membuat format tanggal DD MMMM YYYY HH:mm:ss
     * @param {string} date : tanggal dengan format 01/01/1990 atau 01-01-1990 00:00:00
     * @success : akan memunculkan format tanggal 01 January 1990 00:00:00
     * @fail : akan memunculkan teks strip '-'
     */
    dateFormat(date) {
      return date ? moment(date).format('DD MMMM YYYY HH:mm:ss') : '-'
    },
    /**
     * @function currencyFormat : sebuah function untuk membuat format nominal dengan separator comma (,)
     * @param {number} nominal : numeric atau string seperti 100000 atau '100000'
     * @success : akan memunculkan format nominal dengan 100.000
     * @fail : akan memunculkan teks kosong ''
     */
    currencyFormat(nominal) {
      const nom = parseInt(nominal, 10)
      if (!!nom || nom === 0) {
        const numberNom = nom
        return numberNom
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
          .replace(/\./g, ',')
          .toString()
      }
      return ''
    },
    /**
     * @function isNumber : sebuah function untuk memvalidasi apakah pengguna mengetik angka atau tidak
     * @param {event} event : event keyboard
     * @success : akan memunculkan inputan angka saja, selain angka tidak akan ter-input
     * @fail : selain angka dianggap bukan inputan
     */
    isNumber(event) {
      const evt = event || window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      }
      return true
    },
    /**
     * @function isNumberWithoutZero : sebuah function untuk memvalidasi apakah pengguna mengetik angka kecuali 0 atau tidak
     * @param {event} event : event keyboard
     * @success : akan memunculkan inputan angka saja, selain angka tidak akan ter-input, dan input 0 juga tidak terbaca
     * @fail : selain angka kecuali 0 dianggap bukan inputan
     */
    isNumberWithoutZero(event) {
      const evt = event || window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      const targetValue = Number(evt.target.value)
      if (targetValue.length === 0 && charCode === 48 || targetValue < 0) {
        evt.preventDefault()
      }
      if (
        (
          charCode > 31 &&
          (charCode < 48 || charCode > 57)
        ) ||
        charCode === 46
      ) {
        evt.preventDefault()
      }
      return true
    },
    /**
     * @function convertPhoneNumber : sebuah function untuk handling nomor telepon
     * @param {string} phone : angka atau string
     * @success : akan menampilkan apapun menjadi +62xxxxx, jika user menginput 0, 8, maupun 62
     * @fail : handling gagal dengan hasil null
     */
    convertPhoneNumber(phone) {
      let phoneNumber = phone.toString()
      if (phoneNumber.startsWith('08')) {
        const newphone = phoneNumber.substring(1)
        phoneNumber = `+62${newphone}`
        return phoneNumber
      }
      if (phoneNumber.startsWith('8')) {
        phoneNumber = `+62${phoneNumber}`
        return phoneNumber
      }
      if (phoneNumber.startsWith('62')) {
        const newphone = phoneNumber.replace(/[^0-9\\.]+/g, '')
        const numPhone = newphone.replace('62', '+62')
        phoneNumber = numPhone
        return phoneNumber
      }
      return phone
    },
    /**
     * @function dontAllowSpace : sebuah function untuk tidak memperbolehkan menggunakan spasi
     * @param {event} event : event keyboard
     * @success : saat user mencoba menekan spasi tidak akan bisa
     * @fail : saat user mencoba menekan spasi ternyata bisa
     */
    dontAllowSpace(event) {
      const evt = event || window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode === 32) {
        evt.preventDefault()
      }
      return true
    },
    /**
     * @function convertPhoneNumberWithoutZero : sebuah function untuk handling nomor telepon
     * @param {string} phone : angka atau string
     * @success : akan menampilkan apapun menjadi 8xxxxx, jika user menginput 0, maupun 62
     * @fail : handling gagal dengan hasil null
     */
    convertPhoneNumberWithoutZero(phone) {
      let phoneNumber = phone.toString()
      if (phoneNumber.startsWith('08')) {
        const newphone = phoneNumber.substring(1)
        phoneNumber = newphone
        return phoneNumber
      }
      if (phoneNumber.startsWith('62')) {
        const newphone = phoneNumber.substring(2)
        phoneNumber = newphone
        return phoneNumber
      }
      return phone
    },
    /**
     * @function convertPhoneNumberWithoutPlus : sebuah function untuk handling nomor telepon
     * @param {string} phone : angka atau string
     * @success : akan menampilkan apapun menjadi 62xxxxx, jika user menginput 0, 8
     * @fail : handling gagal dengan hasil null
     */
    convertPhoneNumberWithoutPlus(phone = '') {
      let phoneNumber = phone.toString()
      if (phoneNumber.startsWith('08')) {
        const newphone = phoneNumber.substring(1)
        phoneNumber = `62${newphone}`
        return phoneNumber
      }
      if (phoneNumber.startsWith('8')) {
        phoneNumber = `62${phoneNumber}`
        return phoneNumber
      }
      return phone
    },
    /**
     * @function convertPhoneNumberFirstZero : sebuah function untuk handling nomor telepon
     * @param {string} phone : angka atau string
     * @success : akan menampilkan apapun menjadi 08xxxxx, jika user menginput 8, 62, +62
     * @fail : handling gagal dengan hasil null
     */
    convertPhoneNumberFirstZero(phone = '') {
      let phoneNumber = phone.toString()
      if (phoneNumber.startsWith('8')) {
        phoneNumber = `0${phoneNumber}`
        return phoneNumber
      }
      if (phoneNumber.startsWith('62')) {
        const newphone = phoneNumber.replace(/[^0-9\\.]+/g, '')
        const numPhone = newphone.replace('62', '0')
        phoneNumber = numPhone
        return phoneNumber
      }
      return phone
    },
    /**
     * @function calculateDisc : sebuah function untuk kalkulasi diskon
     * @param {number} price : harga
     * @param {number} disc : persentase diskon
     * @success : akan menampilkan jumlah harga dikurangi harga diskon
     */
    calculateDisc(price, disc) {
      const result = 100 - (disc / price) * 100
      return result % 2 === 0 ? result : result.toFixed(1)
    },
    /**
     * @function mapId : function untuk menghapus type id
     * @param {string} type
     * @param {string} id
     * @success : akan menampilkan id tanpa type
     */
    mapId(type, id) {
      return id ? id.replace(type, '') : ''
    },
    /**
     * @function goTo : function untuk menuju ke halaman
     * @param {string} url
     * @success : akan menampilkan halaman yang dituju
     */
    goTo(urlOrOption) {
      this.$router.push(urlOrOption)
    },
    redirect(url) {
      this.$router.replace(url);
    },
    /**
     * @function showAddress : function untuk menampilkan alamat lengkap
     * @param {object} merchant
     * @success : akan menampilkan alamat lengkap
     */
    showAddress(merchant) {
      if (merchant) {
        return `${merchant.address}, Telp. ${(!!merchant.User && merchant.User.phone) || ''}`
      } else {
        return ''
      }
    },
    /**
     * @function copyToClipboard : function untuk copy text
     * @param {string} text
     * @success : akan copy ke clipboard
     */
    copyToClipboard(text) {
      const { container } = this.$refs
      const app = this
      this.$copyText(text, container).then((e) => {
        app.$snackbar.showMessage({
          isShow: true,
          text: 'Sukses menyalin alamat',
          registered: 'COPY-TEXT',
          color: 'success',
        })
      })
    },
    /**
     * @function getCheckoutData : function untuk kalkulasi total harga, diskon dan total order
     * @param {object} cart : item item cart
     * @success : akan menampilkan jumlah total harga, diskon dan total order dari item cart
     */
    getCheckoutData(cart, {
      paymentMethod, orderAdditionalFee, orderDiscountAmount, orderStatus,
      orderDiscountPercentage, orderLogisticFee, selectedVoucher,
      voucherFee, voucherLogisticFee, logisticFeeEstimation, enableLogisticFeeEstimation
    }) {
      let totalPrice = 0
      let itemDiscount = 0
      let totalOrder = 0
      let totalProduct = 0
      let adminFee = 0
      let totalOrderPrice = 0
      let additionalFee = !isNaN(orderAdditionalFee) ? Math.round(orderAdditionalFee) : 0
      let orderDiscount = 0
      let totalFinalPrice = 0
      const availableCarts = cart.filter((item) => (item.available || item.isCartAvailable))
      if (availableCarts.length > 0) {
        totalPrice = availableCarts.reduce((sum, b) => {
          const price = b.unit.price || b.price
          return sum + (price * b.qty || 0)
        }, 0)
        itemDiscount = availableCarts.reduce((sum, b) => {
          const price = b.unit.price || b.price
          const discountPrice = b.unit.discount_price || b.discount_price
          return (
            sum +
            ((discountPrice && discountPrice > 0 ? price - discountPrice : 0) *
              b.qty || 0)
          )
        }, 0)
        totalOrder = availableCarts.reduce((a, b) => a + b.qty, 0)
        totalProduct = availableCarts.length
      }

      totalOrderPrice = parseInt(totalPrice - itemDiscount)

      if (!isNaN(orderDiscountAmount) && orderDiscountAmount > 0) {
        orderDiscount = orderDiscountAmount
      }
      if (!isNaN(orderDiscountPercentage) && orderDiscountPercentage > 0) {
        orderDiscount = (orderDiscountPercentage * totalOrderPrice) / 100
      }

      if (paymentMethod) {
        if (paymentMethod.percentage_amount > 0)
          adminFee = parseInt(
            Math.round((totalOrderPrice * paymentMethod.percentage_amount) / 100)
          )
        else if (paymentMethod.amount) adminFee = paymentMethod.amount
      }

      // calculate real additional fee
      if (orderAdditionalFee > 0) {
        const left = orderAdditionalFee - adminFee
        additionalFee = parseInt(left)
      }

      /**
       * calculate selected voucher
       * in checkout page/button-summary component
       */
      let appliedVoucherFee = 0
      let appliedVoucherDeliveryFee = 0
      if (selectedVoucher) {
        // AppliedVoucher from /check-voucher
        appliedVoucherFee = selectedVoucher?.AppliedVoucher?.discount_amount
        appliedVoucherDeliveryFee = selectedVoucher?.AppliedVoucher?.discount_delivery_fee
      }
      let logisticFee = 0
      let logisticFeeEstimationAmount = 0
      if (enableLogisticFeeEstimation && logisticFeeEstimation && logisticFeeEstimation.amount) {
        logisticFee = logisticFeeEstimation.amount
        logisticFeeEstimationAmount = logisticFeeEstimation.amount
      } else if (!isNaN(orderLogisticFee)) {
        logisticFee = orderLogisticFee
      }
      totalFinalPrice = totalOrderPrice + adminFee +
        (Math.max(0, logisticFee - (voucherLogisticFee || appliedVoucherDeliveryFee))) +
        additionalFee - orderDiscount - (voucherFee || appliedVoucherFee)

      return {
        logisticFee,
        logisticFeeEstimation: logisticFeeEstimationAmount,
        totalPrice,
        deduction: itemDiscount,
        totalOrder,
        totalProduct,
        adminFee,
        totalOrderPrice,
        additionalFee,
        orderDiscount,
        totalFinalPrice,
      }
    },
    /**
     * @function convertStatusOrder : function untuk konversi status order menjadi teks
     * @param {string} status : status order
     * @success : akan menampilkan teks hasil konversi, statu lunas/belum, dan pin color
     */
    convertStatusOrder(status, isPickingUp = false) {
      let text = ''
      let pin = 'pin pin--pending mr-2'
      let color = 'created'
      const isPaid =
        status === 'shipped' ||
        status === 'on_delivery' ||
        status === 'completed'

      if (status === 'created') text = 'Menunggu Konfirmasi'
      if (status === 'accepted') text = 'Menunggu Diproses'
      if (status === 'shipped' || status === 'on_delivery')
        text = isPickingUp ? 'Pesanan Siap Diambil' : 'Pesanan Dikirim'
      if (status === 'cancelled') text = 'Pesanan Dibatalkan'
      if (status === 'completed') text = 'Pesanan Selesai'

      if (status === 'cancelled') pin = 'pin pin--error mr-2'
      if (status === 'completed') pin = 'pin pin--success mr-2'
      if (status === 'accepted') pin = 'pin pin--packing mr-2'
      if (status === 'shipped' || status === 'on_delivery') pin = 'pin pin--ready mr-2'

      if (status === 'cancelled') color = 'cancelled'
      if (status === 'completed') color = 'completed'
      if (status === 'accepted') color = 'accepted'
      if (status === 'shipped' || status === 'on_delivery') color = 'ready'

      return { text, isPaid, pin, color }
    },
    /**
     * @function calculateOrder : function untuk kalkulasi qty, price, dan discount
     * @param {object} item
     */
    calculateOrder(item) {
      let totalPrice = 0
      let deduction = 0
      let qty = 0
      let total = 0
      const orderItems = item?.OrderItems?.filter((item) => (!item.Product?.available || item.Product?.available === true))
      if (orderItems.length > 0) {
        totalPrice = orderItems.reduce(
          (a, b) => a + (b.price * b.qty || 0),
          0
        )
        deduction = orderItems.reduce(
          (a, b) =>
            a +
            ((b.discount_price && b.discount_price > 0
              ? b.price - b.discount_price
              : 0) * b.qty || 0),
          0
        )
        qty = orderItems.reduce((a, b) => a + b.qty, 0)
        total = item.logistic_fee + item.additional_fee + (totalPrice - deduction) - (item.voucher_fee || item.voucher_logistic_fee)
      }
      return { total, qty }
    },
    getDynamicStaticImg(url) {
      const isCrediMart = this.$config.APP_NAME === 'credimart';
      if (isCrediMart) return url.replace('/img/', '/img/credimart/')
      return url
    },
    async getBuyerId() {
      try {
        const savedCustomer = await this.$cookies.get('gdc')
        let buyerId = savedCustomer.id

        if (!buyerId) {
          const token = await this.$cookies.get('gdotp')
          if (token) {
            const buyer = await this.$store.dispatch('accounts/getBuyerAccount', { token })
            buyerId = buyer?.id
            if (buyerId) {
              await this.$cookies.set('gdc', { ...savedCustomer, id: buyerId }, {
                path: '/',
                maxAge: 2592000,
              })
            }
          }
        }

        return buyerId ?? '-'
      } catch (error) {
        // Bugsnag.notify(new Error(error))
      }
    },
    async getCoverageAreaId() {
      try {
        const resellerAreaData = await this.$cookies.get('gdb')
        return resellerAreaData.coverageAreaId ?? ''
      } catch (error) {
        // Bugsnag.notify(new Error(error))
      }
    },
    _helper_moengageTrackEvent(eventName, contextId) {
      if (this.$route.query.fromapp === 'true' || this.$route.query.fromapp === true) {
        if (!!window && window.webEventHandler && window.webEventHandler.postMessage) {
          window.webEventHandler.postMessage('moengage-event-' + eventName + (contextId ? '-' + contextId : ''));
        }
      }
    },
    async _helper_postMessageToBuyerApp(event) {
      if (!!window && window.webEventHandler && window.webEventHandler.postMessage) {
          await window.webEventHandler.postMessage(event)
        }
    },
    /**
     *
     * @param {*} number
     * @returns '10RB'
     */
    compactNumberFormat(number) {
      const formatted = Intl.NumberFormat('id-ID', { notation: 'compact' }).format(number)
      return formatted.replace(/\s+/g, '').toUpperCase()
    },
    getVoucherMinPurchase(item, isCompact) {
      if (isCompact) {
        return this.compactNumberFormat(item?.min_total_purchase)
      }
      return `Rp${this.currencyFormat(item?.min_total_purchase)}`
    },
    getVoucherMaxDiscount(item, isCompact) {
      const maxNominalDiscount = item?.max_nominal_discount
      const discountAmount = item?.discount_amount
      const discountDeliveryFee = item?.discount_delivery_fee

      if (isCompact) {
        if (maxNominalDiscount) {
          return this.compactNumberFormat(maxNominalDiscount)
        }
        if (discountAmount) {
          return this.compactNumberFormat(discountAmount)
        }
        if (discountDeliveryFee) {
          return this.compactNumberFormat(discountDeliveryFee)
        }
      }

      if (maxNominalDiscount) {
        return `Rp${this.currencyFormat(maxNominalDiscount)}`
      }
      if (discountAmount) {
        return `Rp${this.currencyFormat(discountAmount)}`
      }
      if (discountDeliveryFee) {
        return `Rp${this.currencyFormat(discountDeliveryFee)}`
      }
    },
    async trackEvents(
      { eventName, eventCategory, eventLabel, eventValue, gtag, path, moengageContextId, trxId },
      trackers // AF: AppsFlyer, GTM: Google Tag Manager
    ) {
      const userId = await this.getBuyerId()
      const coverageAreaId = await this.getCoverageAreaId()

      // Google Analytics event tracking
      if (trackers.includes('GTM') && gtag) {
        if (path) gtag.pageview(path)
        if (userId) gtag.config({ user_id: userId })
        if (userId && coverageAreaId) gtag.set('user_properties', {
          coverage_area_id: coverageAreaId,
          cm_user_id: userId,
          ...(trxId && { transaction_id: trxId })
        })
        if (eventName) gtag.event(eventName, {
          event_category: eventCategory ?? '',
          event_label: eventLabel ?? '',
          ...(eventValue?.transactionId && {transaction_id: eventValue.transactionId}),
        })
      }

      // AppsFlyer event tracking
      if (trackers.includes('AF') && !!window && window.AF) {
        if (userId) window.AF('pba', 'setCustomerUserId', userId)
        if (eventName && eventValue) window.AF('pba', 'event', {
          eventType: 'EVENT',
          eventName,
          eventValue,
        })
      }

      if (fbq && trackers.includes('FB')) { // fbq = FB tracker available
        fbq('track', eventName)
      }

      if (trackers.includes('ME')) {
        this._helper_moengageTrackEvent(eventName, moengageContextId)
      }
    },
    getVoucherDiscount(item) {
      const discountAmount = item?.discount_amount
      const discountPercentage = item?.discount_percentage_amount
      const discountDeliveryFee = item?.discount_delivery_fee
      const discountPercentageDeliveryFee = item?.discount_percentage_delivery_fee

      if (discountAmount) {
        const formatted = Intl.NumberFormat('id-ID', { notation: 'compact', }).format(discountAmount)
        return formatted.replace(/\s+/g, '').toUpperCase()
      }
      if (discountPercentage) {
        return `${discountPercentage}%`
      }
      if (discountDeliveryFee) {
        const formatted = Intl.NumberFormat('id-ID', { notation: 'compact', }).format(discountDeliveryFee)
        return formatted.replace(/\s+/g, '').toUpperCase()
      }
      if (discountPercentageDeliveryFee) {
        return `${discountPercentageDeliveryFee}%`
      }
      return ''
    },
    isVoucherHasMaxDiscount(item) {
      const maxNominalDiscount = item?.max_nominal_discount
      const discountPercentageAmount = item?.discount_percentage_amount
      const discountPercentageDeliveryFee = item?.discount_percentage_delivery_fee

      // no voucher max discount limitation
      if (!maxNominalDiscount && (discountPercentageAmount || discountPercentageDeliveryFee)) return false
      return true
    },
    isFreeDeliveryVoucher(item) {
      return !!item?.discount_delivery_fee || !!item?.discount_percentage_delivery_fee
    },
    getRemainingVoucherTimeSpan(item) {
      const endAt = moment(item?.end_at).utc()
      const current = moment()
      const diff = moment(endAt).diff(current, 'days') + 1
      if (this.isVoucherRunsOut(item)) {
        return 'Habis'
      } else if (diff > 0) {
        return `Berakhir ${diff} hari`
      } else {
        return 'Kadaluarsa'
      }
    },
    isVoucherExpired(item) {
      const endAt = moment(item?.end_at).utc()
      const current = moment()
      const diff = moment(endAt).diff(current, 'days') + 1
      return diff <= 0
    },
    isVoucherRunsOut(item) {
      return item?.voucher_quota !== null && (item?.voucher_quota === item?.voucher_used)
    },
    isVoucherForSpecificCategory(item) {
      return !!item?.has_category
    },
    /**
    * array must be provided, can be null
    * prefix is optional
    */
    mergeCoverageArea(array, prefix = '', enableMultiple = false) {
      if (!array?.length) return ''
      if (!enableMultiple) return array[0]?.id

      const merged = array?.reduce((acc, { id }, index) => {
        if (index > 0) {
          acc += `,${prefix}${id}`
        } else {
          acc += `${prefix}${id}`
        }
        return acc
      }, '')

      return merged
    },
    getVoucherParamErrorMessages() {
      return [
        'Parameter id is required',
        'Parameter username is required',
        'Parameter admin_fee_id is required',
        'Parameter place_id is required',
        'Parameter products is required',
      ]
    },
    _helper_bugsnagNotify(error) {
      Bugsnag.notify(new Error(error))
    },
    getMorakStatusColor(status) {
      let bgColor = '#E2F1FF'
      let color = '#0080FF'
      if (status === 'approved') {
        bgColor = '#E0F3EE'
        color = '#29AB87'
      }
      if (status === 'rejected') {
        bgColor = '#F7E9E7'
        color = '#D14D2A'
      }
      return { bgColor, color }
    }
  },
  async getBuyerAccountStatus() {
    try {
      const token = await this.$cookies.get('gdotp')
      let buyerAccount = null
      if (token) buyerAccount = await this.$store.dispatch('accounts/getBuyerAccount', { token })
      return {
        isBuyerStoreVerified: buyerAccount && buyerAccount.Kyc && buyerAccount.Kyc.store_status === 'verified',
        isBuyerIdentityVerified: buyerAccount && buyerAccount.Kyc && buyerAccount.Kyc.status === 'verified' && savedCustomer.verification_stage === 'selfie_ktp_verified',
      }
    } catch (error) {}
  },
})
