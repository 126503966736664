export const state = () => ({
  // state
  loading: false,
  emptyState: {
    title: '',
    desc: '',
  },
  initMessage: {
    isShow: false,
    text: 'Terjadi kesalahan, silahkan coba lagi.',
    registered: '',
    actionBtnText: '',
    onActionBtnClick: () => {},
  },
  locationInfo: {
    available: false,
    location: '',
  },
})

export const mutations = {
  // mutation
  SET_LOADING(state, data) {
    const s = state
    s.loading = data
  },
  SET_EMPTY_STATE(state, data) {
    const s = state
    s.emptyState = data
  },
  SET_DATA_MESSAGE(state, data) {
    const s = state
    s.initMessage = data
  },
  SET_LOCATION_INFO(state, data) {
    const s = state
    s.locationInfo = data
  },
}

export const getters = {
  // getters
  getLoading(state) {
    return state.loading
  },
  getEmptyState(state) {
    return state.emptyState
  },
  getDataMessage(state) {
    return state.initMessage
  },
  getLocationInfo(state) {
    return state.locationInfo
  },
}

export const actions = {
  // actions
}
