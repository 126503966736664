import colors from 'vuetify/es5/util/colors'
import ClockIcon from '@/components/icons/icon-clock'
import ChevronRightIcon from '@/components/icons/icon-chevron-right'
import ChevronLeftIcon from '@/components/icons/icon-chevron-left'
import ChevronDownIcon from '@/components/icons/icon-chevron-down'
import ChevronUpIcon from '@/components/icons/icon-chevron-up'
import LocationIcon from '@/components/icons/icon-location'
import LocationCircleIcon from '@/components/icons/icon-location-circle'
import ProductIcon from '@/components/icons/icon-product'
import OrderIcon from '@/components/icons/icon-order'
import InfoIcon from '@/components/icons/icon-info'
import WhatsappIcon from '@/components/icons/icon-wa'
import WhatsappIcon2 from '@/components/icons/icon-wa2'
import MagnifyIcon from '@/components/icons/icon-magnify'
import MessageIcon from '@/components/icons/icon-message'
import CreatedIcon from '@/components/icons/icon-created'
import AcceptedIcon from '@/components/icons/icon-accepted'
import CompleteIcon from '@/components/icons/icon-complete'
import ShippedIcon from '@/components/icons/icon-shipped'
import CancelledIcon from '@/components/icons/icon-cancelled'
import OffIcon from '@/components/icons/icon-off'
import OnIcon from '@/components/icons/icon-on'
import RadioOffIcon from '@/components/icons/icon-radio-off'
import RadioOnIcon from '@/components/icons/icon-radio-on'
import ArrowLeftIcon from '@/components/icons/icon-arrow-left'
import ArrowUpIcon from '@/components/icons/icon-arrow-up'
import ArrowDownIcon from '@/components/icons/icon-arrow-down'
import ClearIcon from '@/components/icons/icon-clear'
import GeoIcon from '@/components/icons/icon-geo'
import MarkIcon from '@/components/icons/icon-mark'
import FilterSlidersIcon from '@/components/icons/icon-filter-sliders'
import CheckmarkIcon from '@/components/icons/icon-checkmark'
import InstagramIcon from '@/components/icons/icon-instagram'
import VoucherIcon from '@/components/icons/icon-voucher'
import MoneyIcon from '@/components/icons/icon-money'
import OutlinedCloseIcon from '@/components/icons/icon-outlined-close'
import CheckmarkOutlinedIcon from '@/components/icons/icon-checkmark-outlined'
import CredimartLogogramIcon from '@/components/icons/icon-credimart-logogram'
import CopyIcon from '@/components/icons/icon-copy'

export default function({ app }) {
  return {
    theme: {
      options: {
        customProperties: true,
      },
      dark: false,
      themes: {
        light: {
          ...(app.$config.APP_NAME === 'credistore'
            ? {
                primary: '#0080FF',
                primaryLight: '#E2F1FF',
                primaryDark: '#146DEA',
              }
            : {
                primary: '#EB6F4E',
                primaryLight: '#F7E9E7',
                primaryDark: '#D14D2A',
              }),
          secondary: '#EB6F4E',
          secondaryLight: '#F7E9E7',
          accent: colors.shades.black,
          info: '#56B0FF',
          warning: '#FBD333',
          error: '#F23D3D',
          success: '#29AB87',
          grey: '#9E9E9E',
          softgrey: '#BDBDBD',
          white: '#FFFFFF',
          black: '#1E2023',
        },
        dark: {
          primary:
            app.$config.APP_NAME === 'credistore'
              ? '#0080FF'
              : '#e35818',
          secondary: '#F05E23',
          accent: colors.grey.darken3,
          info: '#56B0FF',
          warning: '#FBD333',
          error: '#F23D3D',
          success: '#29AB87',
        },
      },
    },
    icons: {
      values: {
        arrowLeftIcon: {
          component: ArrowLeftIcon,
        },
        arrowUpIcon: {
          component: ArrowUpIcon,
        },
        arrowDownIcon: {
          component: ArrowDownIcon,
        },
        createdIcon: {
          component: CreatedIcon,
        },
        acceptedIcon: {
          component: AcceptedIcon,
        },
        clearIcon: {
          component: ClearIcon,
        },
        completeIcon: {
          component: CompleteIcon,
        },
        cancelledIcon: {
          component: CancelledIcon,
        },
        shippedIcon: {
          component: ShippedIcon,
        },
        chevronLeftIcon: {
          component: ChevronLeftIcon,
        },
        chevronRightIcon: {
          component: ChevronRightIcon,
        },
        chevronDownIcon: {
          component: ChevronDownIcon,
        },
        chevronUpIcon: {
          component: ChevronUpIcon,
        },
        geoIcon: {
          component: GeoIcon,
        },
        productIcon: {
          component: ProductIcon,
        },
        orderIcon: {
          component: OrderIcon,
        },
        infoIcon: {
          component: InfoIcon,
        },
        locationIcon: {
          component: LocationIcon,
        },
        locationCircleIcon: {
          component: LocationCircleIcon,
        },
        messageIcon: {
          component: MessageIcon,
        },
        magnifyIcon: {
          component: MagnifyIcon,
        },
        markIcon: {
          component: MarkIcon,
        },
        offIcon: {
          component: OffIcon,
        },
        onIcon: {
          component: OnIcon,
        },
        radioOffIcon: {
          component: RadioOffIcon,
        },
        radioOnIcon: {
          component: RadioOnIcon,
        },
        whatsappIcon: {
          component: WhatsappIcon,
        },
        whatsappIcon2: {
          component: WhatsappIcon2,
        },
        filterSlidersIcon: {
          component: FilterSlidersIcon,
        },
        checkmarkIcon: {
          component: CheckmarkIcon,
        },
        instagramIcon: {
          component: InstagramIcon,
        },
        voucherIcon: {
          component: VoucherIcon,
        },
        clockIcon: {
          component: ClockIcon,
        },
        moneyIcon: {
          component: MoneyIcon,
        },
        outlinedCloseIcon: {
          component: OutlinedCloseIcon,
        },
        checkmarkOutlinedIcon: {
          component: CheckmarkOutlinedIcon,
        },
        credimartLogogramIcon: {
          component: CredimartLogogramIcon,
        },
        copyIcon: {
          component: CopyIcon,
        },
      },
      filterSlidersIcon: {
        component: FilterSlidersIcon,
      },
      checkmarkIcon: {
        component: CheckmarkIcon,
      },
    },
  }
}
