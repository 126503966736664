// eslint-disable-next-line consistent-return
export default async({ app, route }) => {
  const savedCustomer = (await app.$cookies.get('gdc')) || {}
  const target = route.params?.slug || route.name || 'pesan'
  if ((!savedCustomer?.last_seen_merchant || savedCustomer.last_seen_merchant !== target) && target !== 'cari') {
    app.$cookies.set('gdc', {
      ...savedCustomer,
      last_seen_merchant: target,
    }, {
      path: '/',
      maxAge: 2592000,
    })
  }
  return true
}
