import Vue from 'vue';
import VueGtag from 'vue-gtag';

export default ({ app }) => {
  Vue.use(VueGtag, {
    config: {
      id: app.$config.GOOGLE_ANALYTICS_ID,
      send_page_view: true,
    },
    customResourceURL: app.router,
    appName: 'CrediMart',
  })
}
