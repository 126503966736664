//
//
//
//
//
//

/**
 * Layout Default
 */
export default {
  name: 'FixedLayout',
}
