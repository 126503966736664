export const state = () => ({
  // state
  activeCard: 'CARD_PHONE_NUMBER_VERIFICATION',
  availableMethods: null,
  selectedMethod: null,
  phoneNumber: null,
  isTradingBuyer: null,
})

export const mutations = {
  // mutation
  SET_ACTIVE_CARD(state, data) {
    const s = state
    s.activeCard = data
  },
  SET_AVAILABLE_METHOD(state, data) {
    const s = state
    s.availableMethods = data.sendtype
  },
  SET_SELECTED_METHOD(state, data) {
    const s = state
    s.selectedMethod = data
  },
  SET_PHONE_NUMBER_OTP(state, data) {
    const s = state
    s.phoneNumber = data
  },
  SET_IS_TRADING_BUYER(state, data) {
    const s = state
    s.isTradingBuyer = data
  },
}

export const getters = {
  // getters
  getActiveCard(state) {
    return state.activeCard
  },
  getMethods(state) {
    const items = [
      {
        icon: '$vuetify.icons.whatsappIcon',
        title: 'Whatsapp ke nomor',
        action: 'VERIFICATION_WHATSAPP',
        otp: {
          phone: state.phoneNumber || null,
          sendtype: -99,
          otp: null,
        },
      },
      {
        icon: '$vuetify.icons.messageIcon',
        title: 'SMS ke nomor',
        action: 'VERIFICATION_PHONE_NUMBER',
        otp: {
          phone: state.phoneNumber || null,
          sendtype: 3,
          otp: null,
        },
      },
    ]
    if (state.availableMethods) {
      return items.filter(
        ({ otp }) => Number(otp.sendtype) === Number(state.availableMethods)
      )
    }
    return items
  },
  getSelectedMethod(state) {
    return state.selectedMethod
  },
  isTradingBuyer(state) {
    return state.isTradingBuyer
  },
}

export const actions = {
  // actions
  async switchActiveCard({ commit }, type) {
    await commit('SET_ACTIVE_CARD', type)
  },
  async selectMethods({ commit }, { otp }) {
    await commit('SET_SELECTED_METHOD', otp)
  },
  async doRequestOTP({ commit }, payload) {
    const url = '/auth/buyer/request-otp'
    const mediaType = payload.sendtype === 3 ? 'sms' : 'wa'
    const data = {
      phone: payload.phone,
      media_type: mediaType,
      ...(!!payload.isCrediMart && { app: 'credimart' })
    }

    await commit('SET_PHONE_NUMBER_OTP', payload.phone)
    return await this.$axios.$post(url, data)
  },
  async doSendOTP({ commit }, payload) {
    const url = '/auth/buyer/send-otp'
    const mediaType = payload.sendtype === 3 ? 'sms' : 'wa'
    const data = {
      phone: payload.phone,
      media_type: mediaType,
      token: payload.recaptchaToken,
      ...(!!payload.isCrediMart && { app: 'credimart' })
    }

    await commit('SET_PHONE_NUMBER_OTP', payload.phone)
    return await this.$axios.$post(url, data)
  },
  async doVerifyOTP(context, payload) {
    const url = '/auth/buyer/verify-otp'

    const res = await this.$axios.$post(url, payload)
    if (res && res.token) {
      await this.$cookies.remove('gdotp')
      await this.$cookies.set('gdotp', res.token, {
        path: '/',
        maxAge: 2592000,
      })
    }
    return res
  },
  async getBuyerAccount({ commit }, { token, sellerId }) {
    const url = '/buyer/account'

    const res = await this.$axios.$get(url, {
      headers: { 'x-access-token': token },
      params: {
        ...(sellerId && { seller_id: sellerId }),
      },
    })

    await commit('SET_IS_TRADING_BUYER', !!res.trading)

    return res
  },
  async getOrganicStatus(context, { token }) {
    const url = '/buyer/account/organic-status'

    const res = await this.$axios.$get(url, {
      headers: { 'x-access-token': token },
    })
    return res
  },
  async getOrderVoucherCount(context, { token }) {
    try {
      const url = '/buyer/check-order-voucher'
      const res = await this.$axios.$get(url, {
        headers: { 'x-access-token': token },
      })
      if (res && res.order_count_cash_completed_paid_voucher) {
        return res.order_count_cash_completed_paid_voucher
      }
      return 0
    } catch (error) {
      return 0
    }
  },
  async requestMorakOTP(context, { token, orderId, email }) {
    return await this.$axios.$post(`/buyer/order/${orderId}/request-otp-morak-loan`, null, {
      headers: { 'x-access-token': token },
    })
  },
  async verifyMorakOTP(context, { token, orderId, otp }) {
    const res = await this.$axios.$post(`/buyer/order/${orderId}/otp-morak-loan`, { otp }, {
      headers: { 'x-access-token': token },
    })
    return res
  },
}
