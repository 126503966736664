export const Loading = () => import('../../components/loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const SnackbarAlert = () => import('../../components/snackbar-alert.vue' /* webpackChunkName: "components/snackbar-alert" */).then(c => wrapFunctional(c.default || c))
export const ActionButtonBuy = () => import('../../components/action/button-buy.vue' /* webpackChunkName: "components/action-button-buy" */).then(c => wrapFunctional(c.default || c))
export const ActionButtonSummary = () => import('../../components/action/button-summary.vue' /* webpackChunkName: "components/action-button-summary" */).then(c => wrapFunctional(c.default || c))
export const AuthMethod = () => import('../../components/auth/method.vue' /* webpackChunkName: "components/auth-method" */).then(c => wrapFunctional(c.default || c))
export const AuthOrderPhoneMethod = () => import('../../components/auth/order-phone-method.vue' /* webpackChunkName: "components/auth-order-phone-method" */).then(c => wrapFunctional(c.default || c))
export const AuthPhoneNumber = () => import('../../components/auth/phone-number.vue' /* webpackChunkName: "components/auth-phone-number" */).then(c => wrapFunctional(c.default || c))
export const AuthVerification = () => import('../../components/auth/verification.vue' /* webpackChunkName: "components/auth-verification" */).then(c => wrapFunctional(c.default || c))
export const Carousel = () => import('../../components/carousel/index.vue' /* webpackChunkName: "components/carousel" */).then(c => wrapFunctional(c.default || c))
export const ContentsCredimartPrivacyPolicy = () => import('../../components/contents/credimart-privacy-policy.vue' /* webpackChunkName: "components/contents-credimart-privacy-policy" */).then(c => wrapFunctional(c.default || c))
export const ContentsCredimartTermCondition = () => import('../../components/contents/credimart-term-condition.vue' /* webpackChunkName: "components/contents-credimart-term-condition" */).then(c => wrapFunctional(c.default || c))
export const ContentsCredistorePrivacyPolicy = () => import('../../components/contents/credistore-privacy-policy.vue' /* webpackChunkName: "components/contents-credistore-privacy-policy" */).then(c => wrapFunctional(c.default || c))
export const ContentsCredistoreTermCondition = () => import('../../components/contents/credistore-term-condition.vue' /* webpackChunkName: "components/contents-credistore-term-condition" */).then(c => wrapFunctional(c.default || c))
export const Counter = () => import('../../components/counter/index.vue' /* webpackChunkName: "components/counter" */).then(c => wrapFunctional(c.default || c))
export const DialogConfirm = () => import('../../components/dialog/confirm.vue' /* webpackChunkName: "components/dialog-confirm" */).then(c => wrapFunctional(c.default || c))
export const DialogDisclaimer = () => import('../../components/dialog/disclaimer.vue' /* webpackChunkName: "components/dialog-disclaimer" */).then(c => wrapFunctional(c.default || c))
export const DialogDownloadApp = () => import('../../components/dialog/download-app.vue' /* webpackChunkName: "components/dialog-download-app" */).then(c => wrapFunctional(c.default || c))
export const DialogMorakRejection = () => import('../../components/dialog/morak-rejection.vue' /* webpackChunkName: "components/dialog-morak-rejection" */).then(c => wrapFunctional(c.default || c))
export const DialogOutOfArea = () => import('../../components/dialog/out-of-area.vue' /* webpackChunkName: "components/dialog-out-of-area" */).then(c => wrapFunctional(c.default || c))
export const DialogSellerContact = () => import('../../components/dialog/seller-contact.vue' /* webpackChunkName: "components/dialog-seller-contact" */).then(c => wrapFunctional(c.default || c))
export const FilterBarBuyer = () => import('../../components/filter-bar/buyer.vue' /* webpackChunkName: "components/filter-bar-buyer" */).then(c => wrapFunctional(c.default || c))
export const FilterBar = () => import('../../components/filter-bar/index.vue' /* webpackChunkName: "components/filter-bar" */).then(c => wrapFunctional(c.default || c))
export const IconsIconAccepted = () => import('../../components/icons/icon-accepted.vue' /* webpackChunkName: "components/icons-icon-accepted" */).then(c => wrapFunctional(c.default || c))
export const IconsIconArrowDown = () => import('../../components/icons/icon-arrow-down.vue' /* webpackChunkName: "components/icons-icon-arrow-down" */).then(c => wrapFunctional(c.default || c))
export const IconsIconArrowLeft = () => import('../../components/icons/icon-arrow-left.vue' /* webpackChunkName: "components/icons-icon-arrow-left" */).then(c => wrapFunctional(c.default || c))
export const IconsIconArrowUp = () => import('../../components/icons/icon-arrow-up.vue' /* webpackChunkName: "components/icons-icon-arrow-up" */).then(c => wrapFunctional(c.default || c))
export const IconsIconCancelled = () => import('../../components/icons/icon-cancelled.vue' /* webpackChunkName: "components/icons-icon-cancelled" */).then(c => wrapFunctional(c.default || c))
export const IconsIconCheckmarkOutlined = () => import('../../components/icons/icon-checkmark-outlined.vue' /* webpackChunkName: "components/icons-icon-checkmark-outlined" */).then(c => wrapFunctional(c.default || c))
export const IconsIconCheckmark = () => import('../../components/icons/icon-checkmark.vue' /* webpackChunkName: "components/icons-icon-checkmark" */).then(c => wrapFunctional(c.default || c))
export const IconsIconChevronDown = () => import('../../components/icons/icon-chevron-down.vue' /* webpackChunkName: "components/icons-icon-chevron-down" */).then(c => wrapFunctional(c.default || c))
export const IconsIconChevronLeft = () => import('../../components/icons/icon-chevron-left.vue' /* webpackChunkName: "components/icons-icon-chevron-left" */).then(c => wrapFunctional(c.default || c))
export const IconsIconChevronRight = () => import('../../components/icons/icon-chevron-right.vue' /* webpackChunkName: "components/icons-icon-chevron-right" */).then(c => wrapFunctional(c.default || c))
export const IconsIconChevronUp = () => import('../../components/icons/icon-chevron-up.vue' /* webpackChunkName: "components/icons-icon-chevron-up" */).then(c => wrapFunctional(c.default || c))
export const IconsIconClear = () => import('../../components/icons/icon-clear.vue' /* webpackChunkName: "components/icons-icon-clear" */).then(c => wrapFunctional(c.default || c))
export const IconsIconClock = () => import('../../components/icons/icon-clock.vue' /* webpackChunkName: "components/icons-icon-clock" */).then(c => wrapFunctional(c.default || c))
export const IconsIconComplete = () => import('../../components/icons/icon-complete.vue' /* webpackChunkName: "components/icons-icon-complete" */).then(c => wrapFunctional(c.default || c))
export const IconsIconCopy = () => import('../../components/icons/icon-copy.vue' /* webpackChunkName: "components/icons-icon-copy" */).then(c => wrapFunctional(c.default || c))
export const IconsIconCreated = () => import('../../components/icons/icon-created.vue' /* webpackChunkName: "components/icons-icon-created" */).then(c => wrapFunctional(c.default || c))
export const IconsIconCredimartLogogram = () => import('../../components/icons/icon-credimart-logogram.vue' /* webpackChunkName: "components/icons-icon-credimart-logogram" */).then(c => wrapFunctional(c.default || c))
export const IconsIconFilterSliders = () => import('../../components/icons/icon-filter-sliders.vue' /* webpackChunkName: "components/icons-icon-filter-sliders" */).then(c => wrapFunctional(c.default || c))
export const IconsIconGeo = () => import('../../components/icons/icon-geo.vue' /* webpackChunkName: "components/icons-icon-geo" */).then(c => wrapFunctional(c.default || c))
export const IconsIconInfo = () => import('../../components/icons/icon-info.vue' /* webpackChunkName: "components/icons-icon-info" */).then(c => wrapFunctional(c.default || c))
export const IconsIconInstagram = () => import('../../components/icons/icon-instagram.vue' /* webpackChunkName: "components/icons-icon-instagram" */).then(c => wrapFunctional(c.default || c))
export const IconsIconLocationCircle = () => import('../../components/icons/icon-location-circle.vue' /* webpackChunkName: "components/icons-icon-location-circle" */).then(c => wrapFunctional(c.default || c))
export const IconsIconLocation = () => import('../../components/icons/icon-location.vue' /* webpackChunkName: "components/icons-icon-location" */).then(c => wrapFunctional(c.default || c))
export const IconsIconMagnify = () => import('../../components/icons/icon-magnify.vue' /* webpackChunkName: "components/icons-icon-magnify" */).then(c => wrapFunctional(c.default || c))
export const IconsIconMark = () => import('../../components/icons/icon-mark.vue' /* webpackChunkName: "components/icons-icon-mark" */).then(c => wrapFunctional(c.default || c))
export const IconsIconMessage = () => import('../../components/icons/icon-message.vue' /* webpackChunkName: "components/icons-icon-message" */).then(c => wrapFunctional(c.default || c))
export const IconsIconMoney = () => import('../../components/icons/icon-money.vue' /* webpackChunkName: "components/icons-icon-money" */).then(c => wrapFunctional(c.default || c))
export const IconsIconOff = () => import('../../components/icons/icon-off.vue' /* webpackChunkName: "components/icons-icon-off" */).then(c => wrapFunctional(c.default || c))
export const IconsIconOn = () => import('../../components/icons/icon-on.vue' /* webpackChunkName: "components/icons-icon-on" */).then(c => wrapFunctional(c.default || c))
export const IconsIconOrder = () => import('../../components/icons/icon-order.vue' /* webpackChunkName: "components/icons-icon-order" */).then(c => wrapFunctional(c.default || c))
export const IconsIconOutlinedClose = () => import('../../components/icons/icon-outlined-close.vue' /* webpackChunkName: "components/icons-icon-outlined-close" */).then(c => wrapFunctional(c.default || c))
export const IconsIconProduct = () => import('../../components/icons/icon-product.vue' /* webpackChunkName: "components/icons-icon-product" */).then(c => wrapFunctional(c.default || c))
export const IconsIconRadioOff = () => import('../../components/icons/icon-radio-off.vue' /* webpackChunkName: "components/icons-icon-radio-off" */).then(c => wrapFunctional(c.default || c))
export const IconsIconRadioOn = () => import('../../components/icons/icon-radio-on.vue' /* webpackChunkName: "components/icons-icon-radio-on" */).then(c => wrapFunctional(c.default || c))
export const IconsIconShipped = () => import('../../components/icons/icon-shipped.vue' /* webpackChunkName: "components/icons-icon-shipped" */).then(c => wrapFunctional(c.default || c))
export const IconsIconVoucher = () => import('../../components/icons/icon-voucher.vue' /* webpackChunkName: "components/icons-icon-voucher" */).then(c => wrapFunctional(c.default || c))
export const IconsIconWa = () => import('../../components/icons/icon-wa.vue' /* webpackChunkName: "components/icons-icon-wa" */).then(c => wrapFunctional(c.default || c))
export const IconsIconWa2 = () => import('../../components/icons/icon-wa2.vue' /* webpackChunkName: "components/icons-icon-wa2" */).then(c => wrapFunctional(c.default || c))
export const InfoCart = () => import('../../components/info/cart.vue' /* webpackChunkName: "components/info-cart" */).then(c => wrapFunctional(c.default || c))
export const InfoCustomer = () => import('../../components/info/customer.vue' /* webpackChunkName: "components/info-customer" */).then(c => wrapFunctional(c.default || c))
export const InfoMap = () => import('../../components/info/map.vue' /* webpackChunkName: "components/info-map" */).then(c => wrapFunctional(c.default || c))
export const InfoMorakTempoStatus = () => import('../../components/info/morak-tempo-status.vue' /* webpackChunkName: "components/info-morak-tempo-status" */).then(c => wrapFunctional(c.default || c))
export const InfoOrder = () => import('../../components/info/order.vue' /* webpackChunkName: "components/info-order" */).then(c => wrapFunctional(c.default || c))
export const InfoProductDescription = () => import('../../components/info/product-description.vue' /* webpackChunkName: "components/info-product-description" */).then(c => wrapFunctional(c.default || c))
export const InfoProduct = () => import('../../components/info/product.vue' /* webpackChunkName: "components/info-product" */).then(c => wrapFunctional(c.default || c))
export const InfoSummary = () => import('../../components/info/summary.vue' /* webpackChunkName: "components/info-summary" */).then(c => wrapFunctional(c.default || c))
export const InfoTimeline = () => import('../../components/info/timeline.vue' /* webpackChunkName: "components/info-timeline" */).then(c => wrapFunctional(c.default || c))
export const ItemCart = () => import('../../components/item/cart.vue' /* webpackChunkName: "components/item-cart" */).then(c => wrapFunctional(c.default || c))
export const ItemMethod = () => import('../../components/item/method.vue' /* webpackChunkName: "components/item-method" */).then(c => wrapFunctional(c.default || c))
export const ItemOption = () => import('../../components/item/option.vue' /* webpackChunkName: "components/item-option" */).then(c => wrapFunctional(c.default || c))
export const ItemOrderProduct = () => import('../../components/item/order-product.vue' /* webpackChunkName: "components/item-order-product" */).then(c => wrapFunctional(c.default || c))
export const ItemOrder = () => import('../../components/item/order.vue' /* webpackChunkName: "components/item-order" */).then(c => wrapFunctional(c.default || c))
export const ItemProduct = () => import('../../components/item/product.vue' /* webpackChunkName: "components/item-product" */).then(c => wrapFunctional(c.default || c))
export const ItemStore = () => import('../../components/item/store.vue' /* webpackChunkName: "components/item-store" */).then(c => wrapFunctional(c.default || c))
export const MorakVerification = () => import('../../components/morak-auth/morak-verification.vue' /* webpackChunkName: "components/morak-verification" */).then(c => wrapFunctional(c.default || c))
export const NavbarBuyerLocation = () => import('../../components/navbar/buyer-location.vue' /* webpackChunkName: "components/navbar-buyer-location" */).then(c => wrapFunctional(c.default || c))
export const NavbarHome = () => import('../../components/navbar/home.vue' /* webpackChunkName: "components/navbar-home" */).then(c => wrapFunctional(c.default || c))
export const NavbarLanding = () => import('../../components/navbar/landing.vue' /* webpackChunkName: "components/navbar-landing" */).then(c => wrapFunctional(c.default || c))
export const NavbarSearchable = () => import('../../components/navbar/searchable.vue' /* webpackChunkName: "components/navbar-searchable" */).then(c => wrapFunctional(c.default || c))
export const NavbarStandard = () => import('../../components/navbar/standard.vue' /* webpackChunkName: "components/navbar-standard" */).then(c => wrapFunctional(c.default || c))
export const PesanCityPicker = () => import('../../components/pesan/city-picker.vue' /* webpackChunkName: "components/pesan-city-picker" */).then(c => wrapFunctional(c.default || c))
export const PesanLocationPicker = () => import('../../components/pesan/location-picker.vue' /* webpackChunkName: "components/pesan-location-picker" */).then(c => wrapFunctional(c.default || c))
export const PesanMapPicker = () => import('../../components/pesan/map-picker.vue' /* webpackChunkName: "components/pesan-map-picker" */).then(c => wrapFunctional(c.default || c))
export const SheetCounter = () => import('../../components/sheet/counter.vue' /* webpackChunkName: "components/sheet-counter" */).then(c => wrapFunctional(c.default || c))
export const SheetDateFilter = () => import('../../components/sheet/date-filter.vue' /* webpackChunkName: "components/sheet-date-filter" */).then(c => wrapFunctional(c.default || c))
export const SheetPaymentMethod = () => import('../../components/sheet/payment-method.vue' /* webpackChunkName: "components/sheet-payment-method" */).then(c => wrapFunctional(c.default || c))
export const SheetProductSort = () => import('../../components/sheet/product-sort.vue' /* webpackChunkName: "components/sheet-product-sort" */).then(c => wrapFunctional(c.default || c))
export const SheetSuccess = () => import('../../components/sheet/success.vue' /* webpackChunkName: "components/sheet-success" */).then(c => wrapFunctional(c.default || c))
export const SkeletonGridListProducts = () => import('../../components/skeleton/grid-list-products.vue' /* webpackChunkName: "components/skeleton-grid-list-products" */).then(c => wrapFunctional(c.default || c))
export const SkeletonListOrder = () => import('../../components/skeleton/list-order.vue' /* webpackChunkName: "components/skeleton-list-order" */).then(c => wrapFunctional(c.default || c))
export const SkeletonListProducts = () => import('../../components/skeleton/list-products.vue' /* webpackChunkName: "components/skeleton-list-products" */).then(c => wrapFunctional(c.default || c))
export const SkeletonProduct = () => import('../../components/skeleton/product.vue' /* webpackChunkName: "components/skeleton-product" */).then(c => wrapFunctional(c.default || c))
export const StateDetail = () => import('../../components/state/detail.vue' /* webpackChunkName: "components/state-detail" */).then(c => wrapFunctional(c.default || c))
export const StateEmpty = () => import('../../components/state/empty.vue' /* webpackChunkName: "components/state-empty" */).then(c => wrapFunctional(c.default || c))
export const StateOrder = () => import('../../components/state/order.vue' /* webpackChunkName: "components/state-order" */).then(c => wrapFunctional(c.default || c))
export const FooterVerification = () => import('../../components/footer/verification/index.vue' /* webpackChunkName: "components/footer-verification" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
