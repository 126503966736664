export default ({ store }, inject) => {
  inject('snackbar', {
    async showMessage(payload) {
      await store.commit('SET_DATA_MESSAGE', payload)
    },
    async hide() {
      await store.commit('SET_DATA_MESSAGE', {
        isShow: false,
        text: '',
      })
    },
  })
}
