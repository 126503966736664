export const state = () => ({
  // state
  order: null,
  statusOrder: false,
  cart: [],
  dataTransaction: null,
  listOrder: [],
  // isOrder: false, UNUSED
})

export const mutations = {
  // mutation
  SET_LIST_ORDER(state, data) {
    const s = state
    s.listOrder = data
  },
  CLEAR_ORDER_ITEM(state) {
    const s = state
    s.order = null;
  },
  SET_ORDER_ITEM(state, data) {
    const s = state
    s.order = data
  },
  SET_ORDER_ITEM_CART(state, data) {
    const s = state
    s.cart = data
  },
  SET_DATA_TRANSACTION(state, data) {
    const s = state
    if (data && data.length > 0) {
      const res = {
        id: data[0].id,
        trx: data[0].transaction_number,
      }

      s.dataTransaction = res
    }
  },
  SET_ORDER_SUCCESS(state, data) {
    const s = state
    s.statusOrder = data
  },
  SET_IS_ORDER(state, data) {
    // const s = state
    // s.isOrder = data
    this.$cookies.set('gdio', data, {
      path: '/',
      maxAge: 2592000,
    })
  },
}

export const getters = {
  // getters
  getListOrder(state) {
    return state.listOrder?.rows || []
  },
  getOrderItem(state) {
    return state.order
  },
  getOrderItemCart(state) {
    return state.cart
  },
  getStatusOrder(state) {
    return state.statusOrder
  },
  getDataTransaction(state) {
    return state.dataTransaction
  },
  // getIsOrder(state) {
  //   let { isOrder } = state
  //   if (this.$cookies.get('gdio')) {
  //     const dataOrder = this.$cookies.get('gdio')
  //     isOrder = dataOrder
  //   }
  //   return isOrder
  // }, UNUSED
}

export const actions = {
  // actions
  async initOrder({ commit }, { payload }) {
    await commit('SET_ORDER_ITEM', payload)
    return true
  },

  async createOrder({ commit }, { payload, token }) {
    // IF READY UNCOMMENT THE CODE BELOW
    const url = '/buyer/order'
    try {
      const res = await this.$axios.$post(url, payload, {
        headers: { 'x-access-token': token },
      })

      const isCheckoutProductUnavailable = res &&
        res.status === 'error' &&
        res.deleted_product_ids &&
        res.deleted_product_ids.length > 0

      if (isCheckoutProductUnavailable) {
        return res
      }

      await this.$cookies.remove('gdo')

      if (res) {
        await commit('SET_DATA_TRANSACTION', {
          id: res[0].id,
          trx: res[0].transaction_number,
          status: true,
        })
        await this.$cookies.set(
          'gdo',
          {
            id: res[0].id,
            trx: res[0].transaction_number,
            status: true,
          },
          {
            path: '/',
            maxAge: 2592000,
          }
        )
      }
      await commit('SET_ORDER_SUCCESS', true)
      return true;
    } catch (error) {
      if (error.response?.status === 403) {
        await this.$cookies.remove('gdotp')
      }
      throw error
    }
  },

  async getOrder({ commit }, { token, id }) {
    commit('SET_LOADING', true, { root: true })
    await commit('CLEAR_ORDER_ITEM')
    try {
      let res = null
      if (token) {
        res = await this.$axios.$get(`/buyer/order/${id}`, { headers: { 'x-access-token': token } })
      } else {
        res = await this.$axios.$get(`/guest/order/${id}`)
      }
      if (res) {
        const items = res.OrderItems.map((el) => {
          const newObj = {
            ...el.Product,
            qty: el.qty,
            price: el.price,
            unit: el.unit,
            discount_price: el.discount_price,
            ratio: el.ratio,
          }
          return newObj
        })

        await commit('SET_ORDER_ITEM', res)
        await commit('SET_ORDER_ITEM_CART', { items })
        await commit('SET_LOADING', false, { root: true })

        return res;
      } else {
        return null;
      }
    } catch (error) {
      await commit('SET_LOADING', false, { root: true })
      throw error
    }
  },

  async fetchListOrder({ commit }, { token, payload }) {
    await commit('SET_LOADING', true, { root: true })
    try {
      const res = await this.$axios.$get(
        '/buyer/order',
        {
          params: payload,
          headers: { 'x-access-token': token },
        }
      )

      if (res) {
        await commit('SET_LIST_ORDER', res)
        await commit('SET_LOADING', false, { root: true })
        return res;
      } else {
        return null;
      }
    } catch (error) {
      await commit('SET_LOADING', false, { root: true })
      throw error
    }
  },

  async markNewOrderItemsAsRead({ commit }, { token, orderId }) {
    await commit('SET_LOADING', true, { root: true })
    try {
      await this.$axios.$post(
        `/buyer/order/${orderId}/read`, null, {
          headers: { 'x-access-token': token },
        }
      )
      await commit('SET_LOADING', false, { root: true })
    } catch (error) {
      await commit('SET_LOADING', false, { root: true })
    }
  },

  async getLoanAgreementLetter(context, { token, orderId }) {
    const res = await this.$axios.$get(`/buyer/loan-agreement/${orderId}`, {
      headers: { 'x-access-token': token },
    })
    return res
  },
}
