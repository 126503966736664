import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1f930876 = () => interopDefault(import('../pages/beli.vue' /* webpackChunkName: "pages/beli" */))
const _83cf47ea = () => interopDefault(import('../pages/cari.vue' /* webpackChunkName: "pages/cari" */))
const _6bc47c88 = () => interopDefault(import('../pages/pesan/index.vue' /* webpackChunkName: "pages/pesan/index" */))
const _740714e6 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _ea033420 = () => interopDefault(import('../pages/term-condition.vue' /* webpackChunkName: "pages/term-condition" */))
const _0b63595a = () => interopDefault(import('../pages/verification-promo.vue' /* webpackChunkName: "pages/verification-promo" */))
const _7c44d4d1 = () => interopDefault(import('../pages/merchant-pos/privacy-policy.vue' /* webpackChunkName: "pages/merchant-pos/privacy-policy" */))
const _cd7363ea = () => interopDefault(import('../pages/merchant-pos/terms-condition.vue' /* webpackChunkName: "pages/merchant-pos/terms-condition" */))
const _074ffa68 = () => interopDefault(import('../pages/pesan/info.vue' /* webpackChunkName: "pages/pesan/info" */))
const _44f70568 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5f4360f2 = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))
const _72ee4e0a = () => interopDefault(import('../pages/_slug/cart.vue' /* webpackChunkName: "pages/_slug/cart" */))
const _556ecea1 = () => interopDefault(import('../pages/_slug/checkout.vue' /* webpackChunkName: "pages/_slug/checkout" */))
const _5e7f68ee = () => interopDefault(import('../pages/_slug/info.vue' /* webpackChunkName: "pages/_slug/info" */))
const _67dcf074 = () => interopDefault(import('../pages/_slug/morak-letter.vue' /* webpackChunkName: "pages/_slug/morak-letter" */))
const _86c76f74 = () => interopDefault(import('../pages/_slug/order/index.vue' /* webpackChunkName: "pages/_slug/order/index" */))
const _d9f65bd4 = () => interopDefault(import('../pages/_slug/pick-location.vue' /* webpackChunkName: "pages/_slug/pick-location" */))
const _437fb94d = () => interopDefault(import('../pages/_slug/tempo-agreement.vue' /* webpackChunkName: "pages/_slug/tempo-agreement" */))
const _02439734 = () => interopDefault(import('../pages/_slug/voucher/index.vue' /* webpackChunkName: "pages/_slug/voucher/index" */))
const _2d57aa38 = () => interopDefault(import('../pages/_slug/order/auth.vue' /* webpackChunkName: "pages/_slug/order/auth" */))
const _ac195012 = () => interopDefault(import('../pages/_slug/order/success.vue' /* webpackChunkName: "pages/_slug/order/success" */))
const _77d0f121 = () => interopDefault(import('../pages/_slug/morak-auth/_id.vue' /* webpackChunkName: "pages/_slug/morak-auth/_id" */))
const _bde8b7a4 = () => interopDefault(import('../pages/_slug/order/_id.vue' /* webpackChunkName: "pages/_slug/order/_id" */))
const _a5cbcf64 = () => interopDefault(import('../pages/_slug/voucher/_id.vue' /* webpackChunkName: "pages/_slug/voucher/_id" */))
const _ff789fea = () => interopDefault(import('../pages/_slug/_product.vue' /* webpackChunkName: "pages/_slug/_product" */))
const _85415532 = () => interopDefault(import('../pages/_slug/product/index.vue' /* webpackChunkName: "pages/_slug/product/index" */))
const _0d2da930 = () => interopDefault(import('../pages/_slug/product/_idproduct.vue' /* webpackChunkName: "pages/_slug/product/_idproduct" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/beli",
    component: _1f930876,
    name: "beli"
  }, {
    path: "/cari",
    component: _83cf47ea,
    name: "cari"
  }, {
    path: "/pesan",
    component: _6bc47c88,
    name: "pesan"
  }, {
    path: "/privacy-policy",
    component: _740714e6,
    name: "privacy-policy"
  }, {
    path: "/term-condition",
    component: _ea033420,
    name: "term-condition"
  }, {
    path: "/verification-promo",
    component: _0b63595a,
    name: "verification-promo"
  }, {
    path: "/merchant-pos/privacy-policy",
    component: _7c44d4d1,
    name: "merchant-pos-privacy-policy"
  }, {
    path: "/merchant-pos/terms-condition",
    component: _cd7363ea,
    name: "merchant-pos-terms-condition"
  }, {
    path: "/pesan/info",
    component: _074ffa68,
    name: "pesan-info"
  }, {
    path: "/",
    component: _44f70568,
    name: "index"
  }, {
    path: "/:slug",
    component: _5f4360f2,
    name: "slug"
  }, {
    path: "/:slug/cart",
    component: _72ee4e0a,
    name: "slug-cart"
  }, {
    path: "/:slug/checkout",
    component: _556ecea1,
    name: "slug-checkout"
  }, {
    path: "/:slug/info",
    component: _5e7f68ee,
    name: "slug-info"
  }, {
    path: "/:slug/morak-letter",
    component: _67dcf074,
    name: "slug-morak-letter"
  }, {
    path: "/:slug/order",
    component: _86c76f74,
    name: "slug-order"
  }, {
    path: "/:slug/pick-location",
    component: _d9f65bd4,
    name: "slug-pick-location"
  }, {
    path: "/:slug/tempo-agreement",
    component: _437fb94d,
    name: "slug-tempo-agreement"
  }, {
    path: "/:slug/voucher",
    component: _02439734,
    name: "slug-voucher"
  }, {
    path: "/:slug/order/auth",
    component: _2d57aa38,
    name: "slug-order-auth"
  }, {
    path: "/:slug/order/success",
    component: _ac195012,
    name: "slug-order-success"
  }, {
    path: "/:slug/morak-auth/:id?",
    component: _77d0f121,
    name: "slug-morak-auth-id"
  }, {
    path: "/:slug/order/:id",
    component: _bde8b7a4,
    name: "slug-order-id"
  }, {
    path: "/:slug/voucher/:id",
    component: _a5cbcf64,
    name: "slug-voucher-id"
  }, {
    path: "/:slug/:product",
    component: _ff789fea,
    children: [{
      path: "",
      component: _85415532,
      name: "slug-product"
    }, {
      path: ":idproduct",
      component: _0d2da930,
      name: "slug-product-idproduct"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
