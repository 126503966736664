import Vue from 'vue';
import GMap from "\u002Fhome\u002Fdeploy\u002Fbuilds\u002FQc_ss9-7\u002F0\u002Fcredibook\u002FJooalanWeb\u002Fnode_modules\u002Fnuxt-gmaps\u002Fcomponents\u002FGMap.vue";
import GMapMarker from "\u002Fhome\u002Fdeploy\u002Fbuilds\u002FQc_ss9-7\u002F0\u002Fcredibook\u002FJooalanWeb\u002Fnode_modules\u002Fnuxt-gmaps\u002Fcomponents\u002FGMapMarker.vue";
import GMapCircle from "\u002Fhome\u002Fdeploy\u002Fbuilds\u002FQc_ss9-7\u002F0\u002Fcredibook\u002FJooalanWeb\u002Fnode_modules\u002Fnuxt-gmaps\u002Fcomponents\u002FGMapCircle.vue";
import GMapInfoWindow from "\u002Fhome\u002Fdeploy\u002Fbuilds\u002FQc_ss9-7\u002F0\u002Fcredibook\u002FJooalanWeb\u002Fnode_modules\u002Fnuxt-gmaps\u002Fcomponents\u002FGMapInfoWindow.vue";
export default(context, inject) =>{
  Vue.component('GMap', GMap);
  Vue.component('GMapMarker', GMapMarker);
  Vue.component('GMapCircle', GMapCircle);
  Vue.component('GMapInfoWindow', GMapInfoWindow);
  inject('GMaps', {apiKey: "AIzaSyDrbFe7EM3fSnvzABF7tka5MojuhuY-oRQ", loaded: false, libraries: ["places","v=weekly"]})
}