//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Component Icon
 */
export default {
  name: 'WhatsappIcon',
}
