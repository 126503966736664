//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex'

/**
 * Layout Home
 */
export default {
  data() {
    return {
      bottomNav: 'product',
      menu: [
        {
          id: 'bottom-nav-product-btn',
          value: 'product',
          text: 'Produk',
          icon: '$vuetify.icons.productIcon',
        },
        {
          id: 'bottom-nav-order-btn',
          value: 'order',
          text: 'Pesanan',
          icon: '$vuetify.icons.orderIcon',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      isTradingBuyer: 'accounts/isTradingBuyer',
    }),
  },
  created() {
    this.$nuxt.$route.name === 'slug' || this.$nuxt.$route.name === this.$config.RESELLER_PATH
      ? (this.bottomNav = 'product')
      : (this.bottomNav = 'order')
  },
  methods: {
    /**
     * Method untuk mengubah navigasi bottom menu
     *
     * @method onChangeNav
     * @param {string} value
     */
    onChangeNav(value) {
      const slug = this.$nuxt.$route.name === this.$config.RESELLER_PATH ? this.$config.RESELLER_PATH : this.$route.params.slug
      if (value === 'product') {
        this.goTo(`/${slug}`)
      } else {
        this.goTo(`/${slug}/order`)
      }
    },
    isDisplayTradingChip(itemId) {
      const isVisible = this.isTradingBuyer && itemId === 'bottom-nav-product-btn'
      return isVisible
    }
  },
}
