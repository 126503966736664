// eslint-disable-next-line consistent-return
export default async({ app, redirect, query }) => {
  const token = (await app.$cookies.get('gdotp')) || null
  const merchant = (await app.$cookies.get('gdmp')) || null

  if (token && !query.switchAccount) {
    const url = merchant ? `/${merchant.username}` : '/'
    return redirect(url)
  }
  return true
}
