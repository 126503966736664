import Bugsnag from '@bugsnag/js'
import { mapProductListResponseToProductListData } from '@/store/merchant'
import qaWhitelistPhoneNumbers from '@/static/js/qa-automation-phone-numbers.json'

export default ({ store, app, redirect, route, $config, query, params }, inject) => {
  inject('items', {
    async createOrder(merchantName, payload) {
      // gdotp = Get Data OTP
      const initToken = (await app.$cookies.get('gdotp')) || null
      if (query.fromapp === 'true' || query.fromapp === true) {
        payload.platform = 'app';
      } else {
        payload.platform = 'web';
      }

      if (initToken) {
        return await store.dispatch('order/createOrder', { payload, token: initToken })
      } else {
        if (query.fromapp === 'true' || query.fromapp === true) {
          if (!!window && window.webEventHandler && window.webEventHandler.postMessage) {
            await window.webEventHandler.postMessage('buyer-auth')
          }
        } else {
          redirect(`/${merchantName}/order/auth`)
        }
        return false;
      }
    },

    async saveOrderAndReLogin(merchantName, payload) {
      const res = await store.dispatch('order/initOrder', { payload })
      if (res) {
        if (query.fromapp === 'true' || query.fromapp === true) {
          if (!!window && window.webEventHandler && window.webEventHandler.postMessage) {
            await window.webEventHandler.postMessage('buyer-auth')
          }
        } else {
          redirect(`/${merchantName}/order/auth`)
        }
      }
    },

    async getProducts(payload) {
      store.commit('SET_LOADING', true)
      const data = await store.dispatch('merchant/getDataProduct', payload)
      await store.commit('SET_LOADING', false)
      return data
    },

    async getResellerProducts(payload) {
      store.commit('SET_LOADING', true)
      const data = await store.dispatch('merchant/getResellerDataProduct', payload)
      await store.commit('SET_LOADING', false)
      return data
    },

    async initCart({
      username = '',
      isExcludeUnavailableProducts = false,
      shouldRefetchCartItems = false
    }) {
      const merchantProfileData = (await app.$cookies.get('gdmp')) || null
      const CART_DATA_KEY = await this.getCartDataKey({})
      let currentCart = localStorage.getItem(CART_DATA_KEY)

      if (merchantProfileData) {
        if (!currentCart) {
          const defaultCartData = { items: [] }
          localStorage.setItem(CART_DATA_KEY, JSON.stringify(defaultCartData))
          return await store.dispatch('merchant/initCart', {
            payload: JSON.stringify(defaultCartData),
            username,
            shouldRefetchCartItems,
          })
        } else {
          const currentCartObj = JSON.parse(currentCart)
          if (isExcludeUnavailableProducts && currentCartObj.items && currentCartObj.items.length > 0) {
            const unavailableProductsFromLocalStorage = localStorage.getItem('unavailable-cart-products')
            let unavailableProductsObj = []
            if (unavailableProductsFromLocalStorage) {
              unavailableProductsObj = JSON.parse(unavailableProductsFromLocalStorage)
            }
            if (unavailableProductsFromLocalStorage && currentCartObj) {
              currentCart = currentCartObj.items.filter(({ id }) => (!unavailableProductsObj.includes(id)))
              const newCurrentCart = { items: currentCart }
              currentCart = JSON.stringify(newCurrentCart)
              store.commit('SET_CART', newCurrentCart)
            }
          }
          const updatedCartItems = await store.dispatch('merchant/initCart', {
            payload: currentCart,
            username,
            shouldRefetchCartItems,
          })
          localStorage.setItem(CART_DATA_KEY, JSON.stringify(updatedCartItems))
          return updatedCartItems
        }
      }
    },

    async initCartOrder(sellerProfileId, username, coverageAreaId = '') {
      const CART_DATA_KEY = await this.getCartDataKey({ sellerProfileId, coverageAreaId })
      const initCartData = { items: [], paymentMethod: {} }
      let currentCart = localStorage.getItem(CART_DATA_KEY)

      if (!currentCart) {
        localStorage.setItem(CART_DATA_KEY, JSON.stringify(initCartData))
        store.dispatch('merchant/initCart', { payload: JSON.stringify(initCartData), username })
      } else {
        if (typeof (currentCart) !== 'string') {
          currentCart = JSON.stringify(currentCart)
        }
        localStorage.setItem(CART_DATA_KEY, currentCart)
        store.dispatch('merchant/initCart', { payload: currentCart, username })
      }
    },

    async resetCart(initialItems = []) {
      const CART_DATA_KEY = await this.getCartDataKey({})
      const initCartData = { items: initialItems }

      localStorage.setItem(CART_DATA_KEY, JSON.stringify(initCartData))
      await app.$cookies.remove('gdhc')
      await app.$cookies.remove('gdio')
    },

    async getCurrentCart() {
      const CART_DATA_KEY = await this.getCartDataKey({})
      const currentCart = localStorage.getItem(CART_DATA_KEY)
      return currentCart
    },

    async addItemToCart(itemData, qty, selectedUnit, isUpdate) {
      const CART_DATA_KEY = await this.getCartDataKey({})
      const currentCart = localStorage.getItem(CART_DATA_KEY)
      const newUnit = itemData.allUnits.find(
        (el) => el.name === selectedUnit && (qty >= el.min_quantity || !el.min_quantity)
      )

      if (!!newUnit) {
        const unit = {
          id: newUnit.id,
          name: newUnit.name,
          price: newUnit.price,
          discount_price: newUnit.discount_price,
          ratio: newUnit.ratio,
          active: newUnit.active,
          available_online: newUnit.available_online,
        }

        const item = {
          payload: {
            cartId: `c-${Math.floor(Math.random() * 1000000)}`,
            ...itemData,
            unit,
            qty,
            selectedUnit,
            isCartAvailable: true,
          },
          currentCart,
          isUpdate,
        }

        const res = await store.dispatch('merchant/addCart', item)

        if (res) {
          localStorage.setItem(CART_DATA_KEY, JSON.stringify(res))
        }
      }
    },

    async addOrderToCart(itemData, qty, selectedUnit, sellerProfileId, coverageAreaId = '') {
      const CART_DATA_KEY = await this.getCartDataKey({ sellerProfileId, coverageAreaId })
      const currentCart = localStorage.getItem(CART_DATA_KEY)

      const newUnit = itemData.allUnits.find(
        (el) => el.name === selectedUnit && qty >= el.min_quantity
      )

      // check variant unit availability
      const isUnitAvailable = itemData.listUnit.find(
        (item) => item.name === selectedUnit
      )

      let isCartAvailable = true
      if (!isUnitAvailable) {
        isCartAvailable = false
      }

      const unit = {
        id: newUnit.id,
        name: newUnit.name,
        price: newUnit.price,
        discount_price: newUnit.discount_price,
        ratio: newUnit.ratio,
        active: newUnit.active,
        available_online: newUnit.available_online,
      }

      const item = {
        payload: {
          cartId: `c-${Math.floor(Math.random() * 1000000)}`,
          ...itemData,
          unit,
          qty,
          selectedUnit,
          isCartAvailable,
        },
        currentCart,
        isUpdate: false,
      }

      const res = await store.dispatch('merchant/addCart', item)

      if (res) {
        localStorage.setItem(CART_DATA_KEY, JSON.stringify(res))
      }
    },

    async removeItemFromCart(cartId) {
      const CART_DATA_KEY = await this.getCartDataKey({})
      const currentCart = localStorage.getItem(CART_DATA_KEY)

      const res = await store.dispatch('merchant/deleteCart', {
        cartId,
        currentCart,
      })

      if (res) {
        localStorage.setItem(CART_DATA_KEY, JSON.stringify(res))
      }
    },

    async removeAllItemFromCart(payload) {
      const CART_DATA_KEY = await this.getCartDataKey({})
      const currentCart = localStorage.getItem(CART_DATA_KEY)

      const res = await store.dispatch('merchant/deleteAllCart', {
        payload,
        currentCart,
      })

      if (res) {
        localStorage.setItem(CART_DATA_KEY, JSON.stringify(res))
      }
    },

    async doRepeatOrder(sellerUsername, orderData) {
      try {
        const sellerProfile = await store.dispatch('merchant/getMerchant', { slug: sellerUsername })
        const sellerUserId = sellerProfile && sellerProfile.User && sellerProfile.User.id
        const coverageAreaId = orderData.coverage_area_id
        await this.initCartOrder(sellerUserId, sellerUsername, coverageAreaId)
        await this.resetCart()
        const isOnBuyerApp = route.query.fromapp === 'true' || route.query.fromapp === true

        /**
         * Get product ids by available status
         */
        const productIds = []
        const resellerProductIds = []
        const unavailableProductIds = []
        for (const orderItem of orderData.OrderItems) {
          if (!!orderItem.Product.reference_product_id) {
            resellerProductIds.push(orderItem.product_id)
          } else {
            productIds.push(orderItem.product_id)
          }
        }
        // if (!!orderData.OrderLogs && !!orderData.OrderLogs.out_of_stock_logs) {
        //   for (const logItem of orderData.OrderLogs.out_of_stock_logs) {
        //     if (!!logItem.order_item.Product.reference_product_id) {
        //       resellerProductIds.push(logItem.order_item.Product.id)
        //     } else {
        //       productIds.push(logItem.order_item.Product.id)
        //     }
        //   }
        // }

        /**
         * Fetch products to get mapped products to add to cart
         */
        const availableProductsMap = new Map()
        if (productIds.length) {
          const response = await app.$axios.$get('/guest/seller-product', {
            params: {
              username: sellerUsername,
              id: productIds.toString(),
              limit: productIds.length,
            },
          })
          if (response?.product && Array.isArray(response?.product?.rows)) {
            response?.product?.rows.forEach((product) => { availableProductsMap[product.id] = product })
          }
        }
        if (resellerProductIds?.length) {
          const coverageAreaId = orderData.coverage_area_id
          if (!!coverageAreaId) {
            const response = await app.$axios.$get('/guest/reseller-products', {
              params: {
                username: sellerUsername,
                id: resellerProductIds.toString(),
                coverage_area_id: coverageAreaId,
                limit: resellerProductIds.length,
              },
            })
            if (response?.product && Array.isArray(response?.product?.rows)) {
              response?.product?.rows.forEach((product) => { availableProductsMap[product.id] = product })
            }
          }
        }

        /**
         * Add to cart mapped products that available by status & unit
         */
        for (const orderItem of orderData.OrderItems) {
          const mappedProducts = availableProductsMap[orderItem.product_id] && mapProductListResponseToProductListData({ product: { rows: [availableProductsMap[orderItem.product_id]] } })
          const foundProduct = !!mappedProducts && !!mappedProducts.rows.length && mappedProducts.rows[0]
          const foundUnit = !!foundProduct && foundProduct.listUnit.find((unit) => unit.name === orderItem.unit)
          if (!!foundProduct && !!foundUnit) {
            await this.addOrderToCart(
              foundProduct,
              orderItem.qty,
              orderItem.unit,
              sellerUserId,
              coverageAreaId,
            )
          } else {
            unavailableProductIds.push(orderItem.product_id)
          }
        }
        // if (!!orderData.OrderLogs && !!orderData.OrderLogs.out_of_stock_logs) {
        //   for (const logItem of orderData.OrderLogs.out_of_stock_logs) {
        //     const productId = logItem.order_item.Product.id
        //     const mappedProducts = availableProductsMap[productId] && mapProductListResponseToProductListData({ product: { rows: [availableProductsMap[productId]] } })
        //     const foundProduct = !!mappedProducts && !!mappedProducts.rows.length && mappedProducts.rows[0]
        //     const foundUnit = !!foundProduct && foundProduct.listUnit.find((unit) => unit.name === logItem.order_item.unit)
        //     if (!!foundProduct && !!foundUnit) {
        //       await this.addOrderToCart(
        //         foundProduct,
        //         logItem.previous_qty,
        //         logItem.order_item.unit,
        //         sellerUserId,
        //         coverageAreaId,
        //       )
        //     } else {
        //       unavailableProductIds.push(logItem.order_item.Product.id)
        //     }
        //   }
        // }

        if (sellerProfile && sellerProfile.reseller) {
          const resellerAreaData = {
            // address: orderData.address,
            // note: orderData.customer_note,
            // location: { latitude: orderData.latitude, longitude: orderData.longitude },
            coverageAreaId,
            // sellerUsername: orderData.SellerDetail && orderData.SellerDetail.User && orderData.SellerDetail.User.username,
            areaPhone: orderData.SellerDetail && orderData.SellerDetail.User && orderData.SellerDetail.User.phone,
          }
          if (!!orderData.District && !!orderData.City) {
            resellerAreaData.area_address = `${orderData.City.name} - ${orderData.District.name}`;
            resellerAreaData.city = orderData.City.name;
            resellerAreaData.district = orderData.District.name
            if (orderData.City.id) resellerAreaData.cityId = orderData.City.id
            if (orderData.District.id) resellerAreaData.districtId = orderData.District.id
            if (!!orderData.State && orderData.State.id) resellerAreaData.stateId = orderData.State.id
          }
          await app.$cookies.set('gdb', resellerAreaData, { path: '/' })

          const savedCustomer = await app.$cookies.get('gdc');
          await app.$cookies.set('gdc', {
            ...savedCustomer,
            address: orderData.address,
            latitude: orderData.latitude,
            longitude: orderData.longitude,
            address_note: orderData.customer_note,
          }, {
            path: '/',
          })
        }

        /**
         * Save products count to storage to show snackbar info in cart page
         */
        const availableProductsCount = Object.keys(availableProductsMap).length
        const unavailableProductsCount = unavailableProductIds.length
        if (unavailableProductsCount > 0) {
          window.localStorage.setItem('available_products_count', availableProductsCount)
          window.localStorage.setItem('unavailable_products_count', unavailableProductsCount)
        }

        if (isOnBuyerApp) {
          if (!!window && window.webEventHandler && window.webEventHandler.postMessage) {
            await window.webEventHandler.postMessage('repeat-order')
          }
        } else {
          app.router.push(`/${orderData.SellerDetail.User.username}/cart`)
        }
      } catch (error) {
        app.$snackbar.showMessage({
          isShow: true,
          text: error.message,
        })
        if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
          Bugsnag.notify(new Error(error))
        }
      }
    },

    async checkUnpaidLastOrder({ sellerProfile }) {
      const isResellerAccount = sellerProfile && sellerProfile.reseller === true
      const isResellerUrl = route?.params?.slug === $config.RESELLER_PATH || route?.name === $config.RESELLER_PATH
      const token = await app.$cookies.get('gdotp')
      let hasUnpaidLastOrder = false

      /**
       * DEV & STAGING WEB ONLY for QA automation testing
       */
      if (process.env.environment !== 'production') {
        const phoneOtpData = await app.$cookies.get('gdpotp')
        if (!!phoneOtpData && !!phoneOtpData.phone) {
          let phoneOtp = phoneOtpData.phone.toString()
          if (phoneOtp.startsWith('8')) phoneOtp = `0${phoneOtp}`
          if (qaWhitelistPhoneNumbers.includes(phoneOtp)) {
            return false
          }
        }
      }

      if (token) {
        // check last order payment status
        if (isResellerAccount && isResellerUrl) {
          const payload = { limit: 1, offset: 0, reseller: true, status: 'created,accepted,shipped,on_delivery,completed' }
          const listOrder = await store.dispatch('order/fetchListOrder', { token, payload })
          if (!!listOrder && listOrder.rows.length > 0) {
            try {
              const lastOrder = listOrder.rows[0]
              if (lastOrder && lastOrder.payment_status === 'unpaid') {
                hasUnpaidLastOrder = true
                const resellerAreaData = await app.$cookies.get('gdb') ?? {}
                let phone = ''
                if (!!resellerAreaData && resellerAreaData.coverageAreaId) {
                  // reseller PIC area whatsapp number or general reseller phone number
                  phone = (resellerAreaData && resellerAreaData.areaPhone) || (sellerProfile && sellerProfile.User && sellerProfile.User.phone ? sellerProfile.User.phone : '')
                }
                let normalizedPhone = phone.toString()
                if (normalizedPhone.startsWith('08')) {
                  normalizedPhone = `62${normalizedPhone.substring(1)}`
                } else if (normalizedPhone.startsWith('8')) {
                  normalizedPhone = `62${normalizedPhone}`
                }
                app.$snackbar.showMessage({
                  isShow: true,
                  text: 'Mohon lunasi order sebelumnya.<br/>Hubungi sales untuk informasi',
                  color: 'error',
                  actionBtnText: 'HUBUNGI',
                  timeout: 5000,
                  async onActionBtnClick() {
                    const textMessage = 'Halo sales CrediMart, saya butuh bantuan mengenai kendala membuat pesanan karena terdapat order belum lunas'
                    if (query.fromapp === 'true' || query.fromapp === true) {
                      if (!!window && window.webEventHandler && window.webEventHandler.postMessage) {
                        await window.webEventHandler.postMessage(`custom-whatsapp-${normalizedPhone}-${textMessage}`)
                      }
                    } else {
                      if (!!window && !!window.open) {
                        window.open(`https://wa.me/${normalizedPhone}?text=${textMessage}`, '_blank')
                      }
                    }
                  }
                })
              }
            } catch (error) {
              if (!!error && !!error.response && error.response.status !== 400 && error.response.status !== 520) {
                Bugsnag.notify(new Error(error))
              }
            }
          }
        }
      }
      return hasUnpaidLastOrder
    },

    async getCartDataKey({ sellerProfileId = '', coverageAreaId = '' }) {
      let sellerId = sellerProfileId
      let areaId = coverageAreaId
      let isTradingBuyer = null

      const sellerProfile = (await app.$cookies.get('gdmp')) || null
      if (!sellerProfileId && sellerProfile) {
        sellerId = sellerProfile.id
      }

      const isResellerUrl = ((route && route.name === $config.RESELLER_PATH) || (params && params.slug === $config.RESELLER_PATH));
      if (isResellerUrl) {
        if (!coverageAreaId) {
          const resellerAreaData = await app.$cookies.get('gdb')
          areaId = resellerAreaData?.coverageAreaId
        }
        isTradingBuyer = await store.getters['accounts/isTradingBuyer']
        if (isTradingBuyer === null || isTradingBuyer === undefined) {
          const token = app.$cookies.get('gdotp')
          if (token) {
            const accountResponse = await store.dispatch('accounts/getBuyerAccount', { token });
            isTradingBuyer = accountResponse.trading;
          }
        }
      }

      return `C-${sellerId}` + (areaId ? '-' + areaId : '') + (isTradingBuyer ? '-trading' : '');
    },

  })
}
