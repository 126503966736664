export default async({ app, redirect }) => {
  const initDataMerchant = (await app.$cookies.get('gdmp')) || null
  const initUrl = initDataMerchant ? `/${initDataMerchant.username}/order` : '/'
  const initIsHasOrder = await app.$cookies.get('gdo')

  // jika tidak ada pesanan yang dibeli sebelumnya
  if (!initIsHasOrder) {
    return redirect(initUrl)
  }

  return true
}
