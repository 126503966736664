//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Layout Landing
 */
export const { BASE_URL, APP_NAME, BRAND_NAME, BRAND_TAGLINE, BRAND_DESCRIPTION } = process?.env
export default {
  head: {
    meta: [
      {
        hid: 'title',
        name: 'title',
        content: `${BRAND_NAME} - ${BRAND_TAGLINE}`,
      },
      {
        hid: 'description',
        name: 'description',
        content: BRAND_DESCRIPTION,
      },
      { ogType: 'website' },
      { ogUrl: BASE_URL },
      { ogTitle: `${BRAND_NAME} - ${BRAND_TAGLINE}` },
      {
        ogDescription: BRAND_TAGLINE,
      },
      {
        ogImage: `${BASE_URL}/${APP_NAME}.png`,
        alt: `${APP_NAME} - ${BRAND_TAGLINE}`,
        type: 'image/png',
      },
      {
        hid: 'og:site_name',
        property: 'og:site_name',
        content: `${BRAND_NAME} - ${BRAND_TAGLINE}`,
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: `${BRAND_NAME} - ${BRAND_TAGLINE}`,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: BRAND_DESCRIPTION,
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: BASE_URL,
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: `${BASE_URL}/${APP_NAME}.png`,
      },
      { hid: 'og:type', property: 'og:type', content: 'website' },
      {
        hid: 'og:image:type',
        property: 'og:image:type',
        content: 'image/png',
      },
    ],
    link: [
      {
        rel: 'stylesheet',
        type: 'text/css',
        href: 'https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css',
        crossorigin: 'anonymous',
      },
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: `/img/${APP_NAME ?? 'credistore'}/favicon.ico`,
      },
    ],
  },
  computed: {
    isCredistore() {
      return this.$config.APP_NAME === 'credistore'
    },
  },
}
