//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Component Loading
 */
export default {
  data: () => ({
    loading: false,
  }),
  methods: {
    /**
     * Method untuk mulai loading
     *
     * @method start
     */
    start() {
      this.loading = true
    },
    /**
     * Method untuk selesai loading
     *
     * @method finish
     */
    finish() {
      this.loading = false
    },
  },
}
