//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VoucherIcon',
}
