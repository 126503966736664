//
//
//
//
//
//

export default {
  name: 'OutlinedCloseIcon',
}
