//
//
//
//
//
//

export default {
  name: 'CopyIcon',
}
