//
//
//
//
//
//
//

export default {
  name: 'CredimartLogogramIcon',
}
