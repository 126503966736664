//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Component Icon
 */
export default {
  name: 'ArrowLeftIcon',
}
