const middleware = {}

middleware['auth-guard'] = require('../middleware/auth-guard.js')
middleware['auth-guard'] = middleware['auth-guard'].default || middleware['auth-guard']

middleware['merchant-name-listener'] = require('../middleware/merchant-name-listener.js')
middleware['merchant-name-listener'] = middleware['merchant-name-listener'].default || middleware['merchant-name-listener']

middleware['verify-order'] = require('../middleware/verify-order.js')
middleware['verify-order'] = middleware['verify-order'].default || middleware['verify-order']

export default middleware
