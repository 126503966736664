import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

Bugsnag.start({
  apiKey: process.env.bugsnagApiKey,
  plugins: [new BugsnagPluginVue()],
  releaseStage: process.env.environment || 'production',
  appVersion: process.env.appVersion
})

const bugsnagVue = Bugsnag.getPlugin('vue')
bugsnagVue.installVueErrorHandler(Vue)
